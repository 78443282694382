<template>
    <div class="container row m-auto my-5">
        <div class="col col-md-7 my-2 d-flex justify-content-center align-items-center">
            <div class="title position-relative">
                <p class="font-size1">
                    HOLA {{ userName }} BIENVENIDO A TU
                </p>
                <p class="font-size2">
                    <strong>ACADEMIA DIGITAL</strong>
                </p>
            </div>
        </div>
        <div class="col col-md-5 d-flex justify-content-center">
            <img class="background" src="@/assets/buho.png" /> 
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            userName: localStorage.getItem("name_user")
        }
    } 
}
</script>
<style>
.font-size1{
    font-size: calc(1em + 0.5vw)
}
.font-size2{
    font-size: calc(1em + 1.2vw)
}
.title{
    bottom: 5vw;
}
</style>